if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
    });
}

if ('serviceWorker' in navigator && 'PushManager' in window) {
  window.addEventListener('beforeinstallprompt', (e) => {

      const installButton = addButton(e);
      const installBtnContainer = document.querySelectorAll('.installBtn');

      if (installBtnContainer.length > 0) {
        Array.from(installBtnContainer).forEach((container) => {
          const button = addButton(e);
          container.appendChild(button)
        })
      }
      else {
        document.body.appendChild(installButton);
      }
  });
}

function addButton(deferredPrompt) {
  const installButton = document.createElement('button');
  installButton.textContent = 'Install the app';
  installButton.style.padding = '10px 20px';
  installButton.classList.add('focus:outline-none', 'text-white', 'bg-yellow-700', 'hover:bg-yellow-800', 'focus:ring-4', 'focus:ring-yellow-500', 'font-medium', 'rounded-lg', 'text-sm', 'px-5', 'py-2.5', 'me-2', 'mb-2', 'dark:focus:ring-yellow-900');
  installButton.style.border = 'none';
  installButton.style.borderRadius = '5px';
  installButton.style.cursor = 'pointer';

  installButton.addEventListener('click', () => {

      deferredPrompt.prompt();

      deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
              console.log('App installed');
          } else {
              console.log('App installation declined');
          }

          installButton.style.display = 'none';
      });
  });

  return installButton;
}